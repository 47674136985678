import React, { useEffect } from "react"
import { navigate, graphql, useStaticQuery } from "gatsby"
import { usePrismicPreview } from "gatsby-source-prismic"

const PreviewPage = ({ location }) => {
  const { allPrismicPage } = useStaticQuery(graphql`
    {
      allPrismicPage {
        nodes {
          uid
        }
      }
    }
  `)
  const pageUIDs = allPrismicPage.nodes.map(node => node.uid)

  const pathResolver = () => doc => {
    console.log(doc)
    const previewedUID = doc.uid
    if (pageUIDs.includes(previewedUID)) {
      return `/${doc.uid}`
    } else {
      return `/app/unpublished`
    }
  }

  const { previewData, path } = usePrismicPreview(location, {
    repositoryName: "directus-nz",
    pathResolver,
  })

  useEffect(() => {
    if (previewData && path) {
      window.__PRISMIC_PREVIEW_DATA__ = previewData
      navigate(path)
    }
  }, [previewData, path])

  return <div>Loading preview...</div>
}

export default PreviewPage
